import React, { useEffect, useState } from 'react'

export default function Onboarding() {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // If isPortrait, redirect to new URL
    if (isPortrait) {
      // Since this is a replacement for mobile, do not push to history, replace instead
      window.location.replace(
        'https://docs.google.com/document/d/1uiprF7N49pAyzSHeN2AH3sPbYM2vB5Iu_VhcSLcmMTE/edit?usp=sharing'
      )
    }
  }, [isPortrait])

  return (
    <iframe
      src="https://docs.google.com/document/d/1uiprF7N49pAyzSHeN2AH3sPbYM2vB5Iu_VhcSLcmMTE/pub?embedded=true"
      title="Onboarding"
      width={window.innerWidth * 0.8}
      height={window.innerHeight * 0.8}
      style={{ border: '0' }}
    ></iframe>
  )
}
