import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function FAQ() {
  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">
        Tips from the CS Professors
      </Typography>
      <Typography>
        <ul>
          <li>
            The professors wrote <a href="https://lmucs.github.io/resources/resources.html">this!</a> Really! Read it!
            It will help!
          </li>
        </ul>
      </Typography>

      <Typography variant="h5" fontWeight="bold">
        Can I only contact the TA who grades for the class I have a question about?
      </Typography>
      <Typography>
        <ul>
          <li>No! CS TAs are general TAs, so you can ask any one of them for help.</li>
        </ul>
      </Typography>

      <Typography variant="h5" fontWeight="bold">
        When should I contact my professor vs. a TA?
      </Typography>
      <Typography>
        <ul>
          <li>
            Ask your professor if you have an assignment-specific question (i.e. “When is this assignment due?” or “How
            should I submit my answer?”).
          </li>
          <li>
            Ask a TA when you have a coding-specific or more general question (i.e. “I can’t figure out why I’m getting
            this output…”, “How would you approach solving this problem?”, or “I can’t wrap my head around this concept
            we learned.”)
          </li>
          <li>If you’re not sure, go ahead and ask a TA! They can redirect you to a professor if necessary</li>
        </ul>
      </Typography>

      <Typography variant="h5" fontWeight="bold">
        If I have further questions, where can I go?
      </Typography>
      <Typography>
        <ul>
          <li>
            If you have questions about the TA program that aren’t answered here, you can contact any of the TAs above
            at the email addresses listed as well as on Slack or Discord, or you can contact the lab manager, Masao
            Kitamura, at masao.kitamura@lmu.edu, or on Slack.
          </li>
        </ul>
      </Typography>
    </Box>
  )
}
