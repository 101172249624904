import React from 'react'

import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { Link } from 'react-router-dom'
import { PAGES } from '../constants/app.constants'

const borderRadius = '8px'

interface MobileDrawerProps {
  drawerWidth: number
  mobileOpen: boolean
  onClose: () => void
}

export default function MobileDrawer(props: MobileDrawerProps) {
  const { mobileOpen, onClose } = props

  return (
    <Drawer
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: props.drawerWidth,
          borderTopRightRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          border: 'lightgray 1px solid',
        },
      }}
      open={mobileOpen}
      variant="temporary"
      onClose={onClose}
    >
      <div>
        <Toolbar
          sx={{
            minHeight: { sm: `55px` },
            alignItems: 'center',
          }}
        >
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            <Typography fontWeight="bold">🦁 LMUCS</Typography>
          </Link>
        </Toolbar>
        <Divider></Divider>
        <List>
          {PAGES.map(text => (
            <ListItem key={text} disablePadding>
              <ListItemButton component={Link} to={text.replace(' ', '_')}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem key="Slack" disablePadding>
            <ListItemButton component={Link} to="https://lmucs.slack.com" target="_blank">
              <ListItemText primary="Slack" />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}
