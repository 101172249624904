import { createTheme } from '@mui/material/styles'
import '../fonts/fonts.css'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Metric, sans-serif',
  },
})
