import React from 'react'

import './App.css'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'

import { ThemeProvider } from '@mui/material/styles'
import { lightTheme } from './utils/themes'

import { Route, Routes, Link } from 'react-router-dom'

import PermanentDrawer from './components/permanent_drawer'
import MobileDrawer from './components/mobile_drawer'
import TutoringSchedule from './pages/TutoringSchedule'
import TeachingSchedule from './pages/TeachingSchedule'
import FAQ from './pages/FAQ'
import Feedback from './pages/Feedback'

import '@fontsource/roboto/500.css'
import Onboarding from './pages/Onboarding'
import Resources from './pages/Resources'

const drawerWidth = 180
const appBarHeight = 56
const margin = 16

/**
 * TODOS: @d5vis
 *
 * * Simplify calendar component to be reusable
 * * Implement remaining pages
 *  * LAB
 *  * Page list icons?
 * * Theming (dark mode, dark blue maybe?)
 * * Update documentation
 */

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const getCurrentPathname = () => {
    return window.location.pathname.replace('/', '').replace('_', ' ') || 'TA Schedule'
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div className="App">
        <PermanentDrawer drawerWidth={drawerWidth} />
        <MobileDrawer drawerWidth={drawerWidth} mobileOpen={mobileOpen} onClose={handleDrawerToggle} />
        <AppBar
          color="inherit"
          elevation={0}
          sx={{
            height: `${appBarHeight - 1}px`,
            width: { sm: `calc(100% - ${drawerWidth - 1}px)` },
            ml: `${drawerWidth}`,
            justifyContent: 'center',
            outline: '1px solid lightgray',
          }}
        >
          <Toolbar sx={{ display: { xs: 'flex', sm: 'none' }, p: 0 }}>
            <IconButton size="large" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Typography fontWeight="bold">🦁 LMUCS</Typography>
            </Link>
          </Toolbar>
          <Breadcrumbs sx={{ display: { xs: 'none', sm: 'block' }, ml: `${margin}px` }}>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              LMUCS
            </Link>
            <Typography>{getCurrentPathname()}</Typography>
          </Breadcrumbs>
        </AppBar>
        <Box
          component="main"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: `calc(${appBarHeight}px + ${margin}px)`,
          }}
        >
          <Card variant="outlined" sx={{ margin: `${margin}px` }}>
            <CardContent>
              <Routes>
                <Route path="/" element={<TutoringSchedule />} />
                <Route path="/ta_schedule" element={<TutoringSchedule />} />
                <Route path="/teaching_schedule" element={<TeachingSchedule />} />
                <Route path="/faq" element={<FAQ />} />
                {/* <Route path="/feedback" element={<Feedback />} /> */}
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/resources" element={<Resources />} />
              </Routes>
            </CardContent>
          </Card>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default App
