import React from 'react'

import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { Link } from 'react-router-dom'
import { PAGES } from '../constants/app.constants'

interface PermanentDrawerProps {
  drawerWidth: number
}

export default function PermanentDrawer(props: PermanentDrawerProps) {
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: props.drawerWidth,
        },
      }}
    >
      <div>
        <Toolbar
          sx={{
            minHeight: { sm: `55px` },
            alignItems: 'center',
          }}
        >
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            <Typography fontWeight="bold">🦁 LMUCS</Typography>
          </Link>
        </Toolbar>
        <Divider></Divider>
        <List>
          {PAGES.map(text => (
            <ListItem key={text} disablePadding>
              <ListItemButton component={Link} to={text.replace(' ', '_')}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem key="Linktree" disablePadding>
            <ListItemButton component={Link} to="https://linktr.ee/lmucs" target="_blank">
              <ListItemText primary="Linktree" />
            </ListItemButton>
          </ListItem>
          <ListItem key="Slack" disablePadding>
            <ListItemButton component={Link} to="https://lmucs.slack.com" target="_blank">
              <ListItemText primary="Slack" />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}
