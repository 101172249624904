export const PAGES = [
  'TA Schedule',
  'Teaching Schedule',
  'FAQ',
  // 'Feedback',
  'Resources',
  'Onboarding',
]

export const RESOURCE_DOCUMENT_ID = '1PkUHhsyFGwRSkPc7IIz5VpnajxFUeePNDSOxLczobqk'
