import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import iCalendarPlugin from '@fullcalendar/icalendar'
import listPlugin from '@fullcalendar/list'

import useAnalytics from '../hooks/useAnalytics'

import { EventInfo } from '../utils/types'
import { TUTORING_EVENTS_SOURCES } from '../utils/events'

export default function TutoringSchedule() {
  const [selected, setSelected] = useState(new Set())

  const [eventInfo, setEventInfo] = useState<EventInfo>({})
  const [open, setOpen] = useState(false)

  useAnalytics()

  const handleClose = () => {
    setOpen(false)
  }

  function handleSelectionChanged(id: String) {
    const newSet = new Set(selected)
    newSet.has(id) ? newSet.delete(id) : newSet.add(id)
    setSelected(newSet)
  }

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {TUTORING_EVENTS_SOURCES.map(source => (
          <Chip
            label={source.id}
            onClick={() => handleSelectionChanged(source.id)}
            variant={selected.has(source.id) ? 'filled' : 'outlined'}
            sx={{
              color: source.color,
              borderColor: source.color,
            }}
          />
        ))}
      </Stack>
      <Typography marginTop="8px">Location: Keck Lab (Doolan 112)</Typography>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, iCalendarPlugin]}
          eventSources={TUTORING_EVENTS_SOURCES.filter(source => selected.has(source.id) || selected.size === 0)}
          nowIndicator={true}
          slotMinTime="08:00:00"
          slotMaxTime="22:00:00"
          contentHeight="auto"
          eventClick={info => {
            const details: EventInfo = {
              title: info.event.title,
              start: info.event.start,
              end: info.event.end,
              startISO: info.event.start?.toLocaleTimeString(),
              endISO: info.event.end?.toLocaleTimeString(),
              description: info.event.extendedProps.description,
            }
            setEventInfo(details)
            setOpen(true)
          }}
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle textAlign="center">{eventInfo.title}</DialogTitle>
          <DialogContent>
            <Typography textAlign="center">
              {eventInfo.startISO} - {eventInfo.endISO}
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <FullCalendar
          plugins={[listPlugin, iCalendarPlugin]}
          initialView="listWeek"
          eventSources={TUTORING_EVENTS_SOURCES.filter(source => selected.has(source.id) || selected.size === 0)}
          contentHeight="auto"
        />
      </Box>
    </Box>
  )
}
