import React, { useEffect, useState } from 'react'
import { generateGoogleDocsEmbedUrl, generateGoogleDocsUrl } from '../utils/googleDocs'
import { RESOURCE_DOCUMENT_ID } from '../constants/app.constants'

export default function Resources() {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // If isPortrait, redirect to new URL
    if (isPortrait) {
      // Since this is a replacement for mobile, do not push to history, replace instead
      window.location.replace(generateGoogleDocsUrl(RESOURCE_DOCUMENT_ID))
    }
  }, [isPortrait])

  return (
    <iframe
      src={generateGoogleDocsEmbedUrl(RESOURCE_DOCUMENT_ID)}
      title="Onboarding"
      width={window.innerWidth * 0.8}
      height={window.innerHeight * 0.8}
      style={{ border: '0' }}
    ></iframe>
  )
}
